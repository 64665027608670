import UAParser, { UAParser } from 'ua-parser-js';
import { Elm } from './app/Main.elm';
import { CryptoService } from '../../matrixid-web-api/src/ts/crypto';
import { PhoneNumberManager } from '../../matrixid-web-api/src/ts/phonenumber';
import { hasUserVerifyingPlatformAuth, WebAuthnService } from '../../matrixid-web-api/src/ts/webauthn';
import * as jose from 'jose'
import { JWEDecryptionFailed } from 'jose/dist/types/util/errors';
import { IDFace } from './idface';

const _global = window as any;

const SAVED_ID = "savedPartyId";
const SAVED_AUTH_TOKEN = "savedAuthToken";

interface CaptchaControlMessage {
  captchaId?: string
}

interface AuthUiConfig {
  captchaSiteId: string
}

interface RememberPartyIdMessage {
  action: "save" | "reset" | "quicklogin";
  partyId: string;
  authToken?: string;
}

interface IDFaceControl {
  action: "start" | "stop"
}

class MatrixIdService {
  private captchaWidgetId: any = undefined;
  app: any;
  webauthnService: WebAuthnService
  cryptoService: CryptoService
  pnService: PhoneNumberManager
  idFaceService: IDFace;

  constructor(app: any) {
    this.app = app;

    this.app.ports.captchaControl.subscribe((act: CaptchaControlMessage) => {
      console.log("Executing reCAPTCHA")
      if (act.captchaId) {
        if (!_global.grecaptcha) {
          this.startReCaptcha(act.captchaId)
        } else {
          this.runRecaptcha(act.captchaId)
        }
      } else {
        this.onCaptchaError("Captcha could not be started. Check internet connection")
      }
    });

    this.app.ports.rememberPartyIdControl.subscribe((act: RememberPartyIdMessage) => {
      if (act.action == "reset") {
        localStorage.removeItem(SAVED_ID);
        localStorage.removeItem(SAVED_AUTH_TOKEN);
      } else if (act.action == "save") {
        localStorage.setItem(SAVED_ID, act.partyId);
        localStorage.removeItem(SAVED_AUTH_TOKEN);
      } else if (act.action == "quicklogin" && act.authToken) {
        localStorage.setItem(SAVED_ID, act.partyId);
        localStorage.setItem(SAVED_AUTH_TOKEN, act.authToken);
      } else {
        console.error("Invalid remember party id control " + act)
      }
    })

    this.webauthnService = new WebAuthnService(app);
    this.cryptoService = new CryptoService(app);
    this.pnService = new PhoneNumberManager(app);
    this.idFaceService = new IDFace(app);
    this.app.ports.idFaceControl.subscribe((act: IDFaceControl) => {
      if (act.action == "start") {
        this.idFaceService.start();
      } else if (act.action == "stop") {
        this.idFaceService.stop();
      }
    })
    _global.onCaptchaComplete = this.onCaptchaComplete;
    _global.onCaptchaError = this.onCaptchaError;

  }

  onCaptchaError = (err: any) => {
    console.log("Captcha error" + err);
    this.app.ports.captchaCallback.send({ success: false });
  }


  onCaptchaComplete = (tok: string) => {
    console.log("Captcha success");
    this.app.ports.captchaCallback.send({ success: true, token: tok });
  }

  // We load reCAPTCHA on demand as it inserts elements into the DOM
  // that the Elm app init splats. It is not possible to ensure that the div is preserved
  // by relying just on browser load as reCAPTCHA seems to do a bunch of stuff async 
  // before adding the div to the document
  private startReCaptcha(siteKey: string) {
    loadReCaptcha(siteKey, this.runRecaptcha, () => {
      this.app.ports.captchaCallback.send({ success: false })
    })
  }

  private runRecaptcha = (siteKey: string) => {
    _global.grecaptcha.enterprise.ready(() => {
      _global.grecaptcha.enterprise.execute(siteKey, { action: 'login' }).then((token: string) => {
        console.log("Sending token " + token)
        this.app.ports.captchaCallback.send({ success: true, token: token });
      })
    })
  }

}

class MatrixIdTokenVerifier {

  private basePath = new URL(document.baseURI).pathname;
  JWKS: (protectedHeader?: jose.JWSHeaderParameters | undefined, token?: jose.FlattenedJWSInput | undefined) => Promise<jose.KeyLike>;
  parser: UAParser;
  pid: string | null;
  authToken: string | null;
  urls: URLSearchParams;

  constructor() {
    var apiHost = new URL(_global.location.origin)
    apiHost.port = apiHost.port == "" ? "" : "8080"
    apiHost.host = "api." + apiHost.host.split(".").splice(1).join(".")
    this.JWKS = jose.createRemoteJWKSet(new URL(".well-known/jwks.json", apiHost))
    this.parser = new UAParser();
    this.pid = localStorage.getItem(SAVED_ID);
    this.authToken = localStorage.getItem(SAVED_AUTH_TOKEN);
    this.urls = new URLSearchParams(location.search);
  }

  private async checkForVideoCapability() {
    const devices = await navigator.mediaDevices.enumerateDevices()
    const videoDevices = devices.filter(device => device.kind === 'videoinput')
    return (videoDevices.length > 0)
  }

  async setupApp() {
    let authRequestPayload = null;
    let captchaSiteId = null;
    let uvpa = await hasUserVerifyingPlatformAuth();
    if (this.urls.has("authRequest")) {
      const authRequestik = this.urls.get("authRequest") as string;
      try {
        await jose.jwtVerify(authRequestik, this.JWKS, {
          issuer: 'https://matrixid.tech',
          audience: 'https://matrixid.tech',
        })

        const authRequestResp = await fetch('/api/suinterest', {
          method: 'GET',
          headers: {
            'content-type': 'application/json;charset=UTF-8',
            'authorization': 'Bearer ' + authRequestik
          }
        });
        if (authRequestResp.ok) {
          const authRequest = await authRequestResp.text();
          const { payload, protectedHeader } = await jose.jwtVerify(authRequest, this.JWKS, {
            issuer: 'https://matrixid.tech',
            audience: 'https://matrixid.tech',
          })
          authRequestPayload =
          {
            authRequest: authRequest
            , validated: true
            , clientName: payload.cn
            , errUrl: payload.errUrl
          }
          captchaSiteId = (await this.loadCaptchaSiteId(authRequest)).captchaSiteId
        } else {
          console.log("Auth request lookup failed with " + authRequestResp.status + ": " + authRequestResp.statusText)
          console.log("Potential error is: " + (await authRequestResp.text()))
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      captchaSiteId = (await this.loadCaptchaSiteId(undefined)).captchaSiteId
    }
    const videoCapable = await this.checkForVideoCapability();

    const flags = {
      authRequest: authRequestPayload
      , hasUVPA: uvpa
      , hasVideo: videoCapable
      , device: JSON.parse(JSON.stringify(this.parser.getDevice()))
      , os: JSON.parse(JSON.stringify(this.parser.getOS()))
      , browser: JSON.parse(JSON.stringify(this.parser.getBrowser()))
      , lang: navigator.language
      , captchaSiteKey: captchaSiteId
      , savedPartyIdentifier: this.pid
      , savedAuthToken: this.authToken
      , basePath: this.basePath
    }
    return flags;
  }

  private async loadCaptchaSiteId(authRequest: string | undefined): Promise<AuthUiConfig> {
    let res;
    if (authRequest) {
      res = await fetch('/api/oidc/config', {
        method: 'GET',
        headers: {
          'content-type': 'application/json;charset=UTF-8',
          'authorization': 'Bearer ' + authRequest
        }
      });
    } else {
      res = await fetch('/api/oidc/config', {
        method: 'GET',
        headers: {
          'content-type': 'application/json;charset=UTF-8',
        }
      });
    }
    return await res.json() as AuthUiConfig
  }
}



const mapp = new MatrixIdTokenVerifier();
mapp.setupApp().then(flags => {
  const app = Elm.Main.init({
    node: document.querySelector('main'),
    flags: flags
  });

  const service = new MatrixIdService(app);
})



const loadReCaptcha = function (siteKey: string, success: (arg: string) => void, failed: () => void) {
  loadScript("https://www.google.com/recaptcha/enterprise.js?render=" + siteKey).then(data => {
    success(siteKey);
  })
    .catch(err => {
      console.log(err);
      failed();
    });

}

const loadScript = (src: string, async = true, type = "text/javascript") => {
  return new Promise((resolve, reject) => {
    try {
      const scriptEle = _global.document.createElement("script");
      scriptEle.type = type;
      scriptEle.async = async;
      scriptEle.src = src;

      scriptEle.addEventListener("load", () => {
        resolve({ status: true });
      });

      scriptEle.addEventListener("error", () => {
        reject({
          status: false,
          message: `Failed to load the script ＄{src}`
        });
      });

      _global.document.body.appendChild(scriptEle);
    } catch (error) {
      reject(error);
    }
  });
};