import { Base64 } from 'js-base64';


export interface JSONPublicKeyCredentialDescriptor {
    id: string; // BufferSource
    transports?: AuthenticatorTransport[];
    type: PublicKeyCredentialType;
}

export interface JSONPublicKeyCredentialUserEntity extends PublicKeyCredentialEntity {
    displayName: string;
    id: string; //BufferSource;
}

export interface JSONPublicKeyCredentialCreationOptions {
    attestation?: AttestationConveyancePreference;
    authenticatorSelection?: AuthenticatorSelectionCriteria;
    challenge: string; //BufferSource
    excludeCredentials?: JSONPublicKeyCredentialDescriptor[];
    extensions?: AuthenticationExtensionsClientInputs;
    pubKeyCredParams: PublicKeyCredentialParameters[];
    rp: PublicKeyCredentialRpEntity;
    timeout?: number;
    user: JSONPublicKeyCredentialUserEntity;
}

interface JSONPublicKeyCredentialRequestOptions {
    allowCredentials?: JSONPublicKeyCredentialDescriptor[];
    challenge: string, //BufferSource;
    extensions?: AuthenticationExtensionsClientInputs;
    rpId?: string;
    timeout?: number;
    userVerification?: UserVerificationRequirement;
}

export interface JWebAuthnVerify {
    credentialId: string,
    clientDataJSON: string,
    authenticatorData: string,
    signature: string
}

type WebAuthnAction = "register" | "verify";

interface WebAuthnActionMsgResponse {
    action: WebAuthnAction
    , payload: any
}

export async function hasUserVerifyingPlatformAuth(): Promise<boolean> {
    let untypedWindow: any = window;
    return untypedWindow.PublicKeyCredential != undefined && untypedWindow.PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable();
}

export class WebAuthnService {
    private huvpa: string = "notchecked";
    private app: any;

    constructor(app: any) {
        this.app = app;
        this.app.ports.doWebAuthnAction.subscribe((act: WebAuthnActionMsgResponse) => {
            if (act.action == "register") {
                const pkcco = this.convertCreateCreds(act.payload);
                this.createCredentials(pkcco);
            } else if (act.action == "verify") {
                console.info(JSON.stringify(act.payload))
                const pkcro = this.convertVerifyCreds(act.payload);
                this.verifyCredentials(pkcro);
            }
        });
    }

    public convertCreateCreds(creds: JSONPublicKeyCredentialCreationOptions): PublicKeyCredentialCreationOptions {
        const encoder = new TextEncoder();
        return {
            attestation: creds.attestation,
            authenticatorSelection: creds.authenticatorSelection,
            challenge: Base64.toUint8Array(creds.challenge),
            excludeCredentials: creds.excludeCredentials?.map(exc => {
                return {
                    id: encoder.encode(exc.id),
                    transports: exc.transports,
                    type: exc.type
                }
            }),
            extensions: creds.extensions,
            pubKeyCredParams: creds.pubKeyCredParams,
            rp: creds.rp,
            timeout: creds.timeout,
            user: {
                displayName: creds.user.displayName,
                id: encoder.encode(creds.user.id),
                name: creds.user.name
            }
        };
    }

    public convertVerifyCreds(wchal: JSONPublicKeyCredentialRequestOptions): PublicKeyCredentialRequestOptions {
        const creds = wchal;
        return {
            allowCredentials: creds.allowCredentials?.map(exc => {
                return {
                    id: Base64.toUint8Array(exc.id),
                    type: exc.type,
                    transports: exc.transports
                }
            }),
            challenge: Base64.toUint8Array(creds.challenge),
            extensions: creds.extensions,
            rpId: creds.rpId,
            timeout: creds.timeout,
            userVerification: creds.userVerification
        } as PublicKeyCredentialRequestOptions;
    }

    public async createCredentials(credsCreate: PublicKeyCredentialCreationOptions) {
        try {
            const creds = await navigator.credentials.create({ publicKey: credsCreate }) as PublicKeyCredential;
            const resp = creds.response as AuthenticatorAttestationResponse;
            const wac = {
                credentialId: creds.id,
                clientDataJSON: Base64.fromUint8Array(new Uint8Array(resp.clientDataJSON), true),
                attestationObject: Base64.fromUint8Array(new Uint8Array(resp.attestationObject), true),
                transports: ["internal"] // fake all transports to be internal
            }
            this.app.ports.webAuthnResponse.send(
                {
                    registerResponse: wac
                }
            )
        } catch (err) {
            console.error("Credential registration failed: " + err);
            this.notifyError(err)
        }
    }

    public async verifyCredentials(resp: PublicKeyCredentialRequestOptions) {
        try {
            const credm = await navigator.credentials.get({ publicKey: resp });
            const cred = credm as PublicKeyCredential
            const assertionResponse = cred.response as AuthenticatorAssertionResponse
            const body = {
                authenticatorData: Base64.fromUint8Array(new Uint8Array(assertionResponse.authenticatorData), true),
                clientDataJSON: Base64.fromUint8Array(new Uint8Array(assertionResponse.clientDataJSON), true),
                signature: Base64.fromUint8Array(new Uint8Array(assertionResponse.signature), true),
                credentialId: cred.id
            };
            this.app.ports.webAuthnResponse.send(
                {
                    verifyResponse: body
                }
            )
        } catch (err) {
            console.error("Credential get failed: " + err);
            this.notifyError(err)
        }
    }

    private notifyError(err: Error) {
        this.app.ports.webAuthnResponse.send(
            {
                errorResponse: { name : err.name, message: err.message }
            }
        );
    }

}