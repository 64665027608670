module Main exposing (..)

import Browser exposing (Document)
import Browser.Navigation as Nav
import Config exposing (resolveAPIBase, resolveHomePage)
import Dashboard
import Done
import Either exposing (Either(..))
import Furniture.Views
import Html exposing (Html, a, div, p, text)
import Html.Attributes exposing (..)
import Identify exposing (Msg(..))
import IdentifyRemote
import Json.Decode exposing (Value, decodeValue, errorToString)
import MatrixId.EnvStartup as Env exposing (delayedInit)
import MatrixId.SharedViews as SharedViews
import MatrixId.Types as Types
    exposing
        ( Origin
        , PartyIdentifierType(..)
        , URLBase(..)
        , baseToURL
        , genOrigin
        )
import MoreInfoNeeded
import Platform.Cmd exposing (Cmd(..))
import Register
import RegisterCredentialRemote
import RegisterDevice
import Route exposing (Route(..))
import Session exposing (AuthType(..), AuthenticatedUser(..), Config, RemMe(..), SessionDetails, configDecoder, mapCfgToRemMe)
import Time exposing (Zone, here)
import Time.Format.Config.Configs exposing (getConfig)
import Url exposing (Url)



-- MAIN


main : Program Value (Env.SuperModel Model) (Env.SuperMsg Msg Value Zone)
main =
    Browser.application
        { init = initWithZone
        , update = postUpdate
        , view = postView
        , subscriptions = postSubscriptions
        , onUrlRequest = Env.Running << ClickedLink
        , onUrlChange = Env.Running << ChangedUrl
        }


initWithZone : Value -> Url -> Nav.Key -> ( Env.SuperModel Model, Cmd (Env.SuperMsg Msg Value Zone) )
initWithZone v u k =
    delayedInit v u k here


postUpdate : Env.SuperMsg Msg Value Zone -> Env.SuperModel Model -> ( Env.SuperModel Model, Cmd (Env.SuperMsg Msg Value Zone) )
postUpdate msg model =
    Env.superUpdate msg model init update


postView : Env.SuperModel Model -> Document (Env.SuperMsg Msg Value Zone)
postView model =
    Env.superView "MatrixId"
        model
        (\url ->
            Furniture.Views.mainView "MatrixId"
                (Url.toString <| baseToURL (Config.resolveHomePage (genOrigin url Nothing)))
                [ SharedViews.progressView "Starting MatrixId..." ( "for MatrixId home", Config.resolveHomePage (genOrigin url Nothing) ) ]
        )
        (\inModel ->
            Furniture.Views.mainView (Tuple.first (getSessionDetails inModel).errUrl)
                (Url.toString <| baseToURL (Config.resolveHomePage (getSessionDetails inModel).origin))
                [ p [ class "has-text-centered subtitle is-3" ] [ text (getTitle inModel) ]
                , viewBySubModel inModel
                ]
        )
        (Furniture.Views.mainView "MatrixId" "mailto:info@matrixid.tech" [ SharedViews.renderCrash ])


postSubscriptions : Env.SuperModel Model -> Sub (Env.SuperMsg Msg iv res)
postSubscriptions model =
    Env.superSubscriptions model subscriptions



-- MODEL


type Msg
    = ChangedUrl Url
    | ClickedLink Browser.UrlRequest
    | GotIdentifyMessage Identify.Msg
    | GotRegisterMessage Register.Msg
    | GotIdentifyRemoteMessage IdentifyRemote.Msg
    | GotRegisterDeviceMessage RegisterDevice.Msg
    | GotDoneMessage Done.Msg
    | GotRegisterDeviceMessageRemote RegisterCredentialRemote.Msg
    | GotMoreInfoNeededMessage MoreInfoNeeded.Msg
    | GotDashboardMessage Dashboard.Msg
    | ResetViewport


type Model
    = Identify (Session.Session Identify.SubModel Model Identify.Msg Msg)
    | IdentifyRemote (Session.Session IdentifyRemote.SubModel Model IdentifyRemote.Msg Msg)
    | Register (Session.Session Register.SubModel Model Register.Msg Msg)
    | RegisterDevice (Session.Session RegisterDevice.SubModel Model RegisterDevice.Msg Msg)
    | MoreInfoNeeded (Session.Session MoreInfoNeeded.SubModel Model MoreInfoNeeded.Msg Msg)
    | RegisterCredentialRemote (Session.Session RegisterCredentialRemote.SubModel Model RegisterCredentialRemote.Msg Msg)
    | LoginComplete (Session.Session Done.SubModel Model Done.Msg Msg)
    | Dashboard (Session.Session Dashboard.SubModel Model Dashboard.Msg Msg)
    | FatalError Session.SessionDetails
    | NotFound String Session.SessionDetails


defaultSession : Nav.Key -> Origin -> AuthenticatedUser -> Config -> ( String, URLBase ) -> Zone -> Session.SessionDetails
defaultSession key origin mar cfg errUrl z =
    { navKey = key
    , currentRoute = Route.Done
    , fatalError = Nothing
    , origin = origin
    , apiBase = resolveAPIBase origin
    , timedOutError = False
    , timedOutRoute = Route.Done
    , authenticatedUser = mar
    , hasUVPA = cfg.hasUVPA
    , hasVideo = cfg.hasVideo
    , partyIdentifier = cfg.savedPartyIdentifier
    , reOTPToken = Nothing
    , interestToken = Nothing
    , device = Types.getDevice cfg
    , deviceOS = cfg.os
    , zone = z
    , locale = cfg.lang
    , timeConfig = getConfig cfg.lang
    , rememberPartyIdentifier = mapCfgToRemMe cfg
    , errUrl = errUrl
    , captchaSiteKey = cfg.captchaSiteKey
    , skippedCredentials = []
    }


errorSession : Nav.Key -> Origin -> AuthenticatedUser -> Config -> Zone -> Session.SessionDetails
errorSession key origin mar cfg z =
    { navKey = key
    , currentRoute = Route.Done
    , fatalError = Nothing
    , origin = origin
    , apiBase = resolveAPIBase origin
    , timedOutError = False
    , timedOutRoute = Route.Done
    , authenticatedUser = mar
    , hasUVPA = False
    , hasVideo = False
    , partyIdentifier = Nothing
    , reOTPToken = Nothing
    , interestToken = Nothing
    , device = Types.getDevice cfg
    , deviceOS = cfg.os
    , zone = z
    , locale = cfg.lang
    , captchaSiteKey = ""
    , timeConfig = getConfig cfg.lang
    , rememberPartyIdentifier = DoNothing
    , errUrl = ( "MatrixId", resolveHomePage origin )
    , skippedCredentials = []
    }


init : Value -> Url -> Nav.Key -> Zone -> ( Model, Cmd Msg )
init orig url key zone =
    let
        cfgm =
            decodeValue configDecoder orig
    in
    case cfgm of
        Ok cfg ->
            initByRoute cfg (genOrigin url cfg.basePath) url key zone

        Err er ->
            let
                origin =
                    genOrigin url Nothing
            in
            ( FatalError
                ({ navKey = key
                 , currentRoute = Route.Done
                 , fatalError = Nothing
                 , origin = origin
                 , apiBase = resolveAPIBase origin
                 , timedOutError = False
                 , timedOutRoute = Route.Done
                 , authenticatedUser = FailedUnknown "Start up configuration not parsed"
                 , hasUVPA = False
                 , hasVideo = False
                 , partyIdentifier = Nothing
                 , reOTPToken = Nothing
                 , interestToken = Nothing
                 , device = { name = "Unknown Device", browser = Nothing }
                 , deviceOS = { name = Types.UnknownOS "Not detected", version = Nothing }
                 , zone = zone
                 , locale = "en_AU"
                 , captchaSiteKey = ""
                 , timeConfig = getConfig "en_AU"
                 , rememberPartyIdentifier = DoNothing
                 , errUrl = ( "MatrixId", resolveHomePage origin )
                 , skippedCredentials = []
                 }
                    |> (\m -> { m | fatalError = Just ("Configuration could not be parsed: " ++ errorToString er) })
                )
            , Cmd.none
            )


initByRoute : Session.Config -> Origin -> Url -> Nav.Key -> Zone -> ( Model, Cmd Msg )
initByRoute cfg origin ourl key zone =
    case cfg.authRequest of
        Nothing ->
            case Route.fromUrl (Maybe.withDefault "/" origin.basePath) ourl of
                Just (Route.StartRegisterDeviceRemote (Just itok)) ->
                    let
                        sd =
                            defaultSession key
                                origin
                                RemoteAuth
                                cfg
                                ( "MatrixId"
                                , resolveHomePage origin
                                )
                                zone
                    in
                    RegisterCredentialRemote.init itok
                        RegisterCredentialRemote
                        GotRegisterDeviceMessageRemote
                        { sd | interestToken = Just itok }
                        |> (\( m2, c2 ) -> ( m2, Cmd.batch [ SharedViews.clearUrl origin key "srdr", c2 ] ))

                Just (Route.IdentifyRemote (Just iktok)) ->
                    identifyRemoteInit
                        (defaultSession key
                            origin
                            RemoteAuth
                            cfg
                            ( "MatrixId"
                            , resolveHomePage origin
                            )
                            zone
                        )
                        iktok
                        |> (\( m2, c2 ) -> ( m2, Cmd.batch [ SharedViews.clearUrl origin key "identifyRemote", c2 ] ))

                Just Route.Dashboard ->
                    identityInit key origin cfg ( "MatrixId", resolveHomePage origin ) zone (Session.LocalRoute Route.Dashboard)

                Just Route.RegisterDevice ->
                    identityInit key origin cfg ( "MatrixId", resolveHomePage origin ) zone (Session.LocalRoute Route.Dashboard)

                _ ->
                    ( FatalError
                        (errorSession key
                            origin
                            (FailedUnknown "No OIDC request")
                            cfg
                            zone
                            |> (\m -> { m | fatalError = Just "Sorry we can process this MatrixId verification request. Please check with the site that sent you here." })
                        )
                    , Cmd.none
                    )

        Just arp ->
            if arp.validated then
                let
                    hp =
                        Maybe.map (URLBase >> Tuple.pair arp.clientName) (Url.fromString arp.errUrl)
                            |> Maybe.withDefault (Tuple.pair arp.clientName (resolveHomePage origin))
                in
                processLocalRoutes cfg origin ourl key hp zone (Session.OidcAuth (Types.JWT arp.authRequest))

            else
                ( FatalError
                    (errorSession key
                        origin
                        FailedExpired
                        cfg
                        zone
                        |> (\m -> { m | fatalError = Just "This authentication request could not be verified or is out of date" })
                    )
                , Cmd.none
                )


processLocalRoutes : Session.Config -> Origin -> Url -> Nav.Key -> ( String, URLBase ) -> Zone -> AuthType -> ( Model, Cmd Msg )
processLocalRoutes cfg origin ourl key errUrl zone ar =
    let
        minSession auth =
            defaultSession key
                origin
                auth
                cfg
                errUrl
                zone

        minClearUrl =
            SharedViews.clearUrl origin key
    in
    case Route.fromUrl (Maybe.withDefault "/" origin.basePath) ourl of
        Just Route.Identify ->
            identityInit key origin cfg errUrl zone ar

        Just Route.Register ->
            Register.init Register
                GotRegisterMessage
                (minSession
                    (Session.PendingAuth ar)
                )
                |> (\( m2, c2 ) -> ( m2, Cmd.batch [ minClearUrl "register", c2 ] ))

        Just (Route.StartRegisterDeviceRemote (Just itok)) ->
            let
                sd =
                    minSession Session.RemoteAuth
            in
            RegisterCredentialRemote.init itok
                RegisterCredentialRemote
                GotRegisterDeviceMessageRemote
                { sd | interestToken = Just itok, timedOutRoute = Route.Done }
                |> (\( m2, c2 ) -> ( m2, Cmd.batch [ minClearUrl "srdr", c2 ] ))

        Just Route.NotFound ->
            ( NotFound
                (Url.toString ourl)
                (minSession (PendingAuth ar)
                    |> (\m ->
                            { m
                                | currentRoute = Route.NotFound
                            }
                       )
                )
            , minClearUrl "notfound"
            )

        Nothing ->
            ( NotFound
                (Url.toString ourl)
                (minSession (FailedUnknown "URL could not be parsed")
                    |> (\m ->
                            { m
                                | currentRoute = Route.NotFound
                            }
                       )
                )
            , minClearUrl "notfound"
            )

        Just _ ->
            identityInit key origin cfg errUrl zone ar


identifyRemoteInit : SessionDetails -> Types.JWT Types.InterestToken -> ( Model, Cmd Msg )
identifyRemoteInit sessionDetails iktok =
    IdentifyRemote.init
        IdentifyRemote
        GotIdentifyRemoteMessage
        { sessionDetails | interestToken = Just iktok, authenticatedUser = RemoteAuth }


registerDeviceInit :
    SessionDetails
    -> Maybe (Types.JWT Types.InterestToken)
    -> ( Model, Cmd Msg )
registerDeviceInit sessionDetails mitok =
    RegisterDevice.init
        sessionDetails.currentRoute
        RegisterDevice
        GotRegisterDeviceMessage
        { sessionDetails | interestToken = mitok }


identityInit : Nav.Key -> Origin -> Session.Config -> ( String, URLBase ) -> Zone -> AuthType -> ( Model, Cmd Msg )
identityInit key origin cfg errUrl zone ar =
    case ( mapCfgToRemMe cfg, cfg.savedPartyIdentifier, cfg.savedAuthToken ) of
        ( QuikLogin, Just pii, Just tok ) ->
            Done.initQuickLogin (Types.JWT tok)
                pii
                ar
                LoginComplete
                GotDoneMessage
                (defaultSession
                    key
                    origin
                    (PendingAuth ar)
                    cfg
                    errUrl
                    zone
                )

        _ ->
            Identify.init Identify
                GotIdentifyMessage
                (defaultSession key
                    origin
                    (Session.PendingAuth ar)
                    cfg
                    errUrl
                    zone
                )
                |> (\( m2, c2 ) -> ( m2, Cmd.batch [ SharedViews.clearUrl origin key "identify", c2 ] ))


subscriptions : Model -> Sub Msg
subscriptions model =
    case model of
        Identify subModel ->
            Sub.map GotIdentifyMessage (Identify.subscriptions subModel)

        IdentifyRemote subModel ->
            Sub.map GotIdentifyRemoteMessage (IdentifyRemote.subscriptions subModel)

        Register subModel ->
            Sub.map GotRegisterMessage (Register.subscriptions subModel)

        MoreInfoNeeded subModel ->
            Sub.map GotMoreInfoNeededMessage (MoreInfoNeeded.subscriptions subModel)

        RegisterDevice subModel ->
            Sub.map GotRegisterDeviceMessage (RegisterDevice.subscriptions subModel)

        RegisterCredentialRemote subModel ->
            Sub.map GotRegisterDeviceMessageRemote (RegisterCredentialRemote.subscriptions subModel)

        _ ->
            Sub.none



-- UPDATE


update : Msg -> Model -> ( Model, Cmd Msg )
update msg model =
    let
        handleSubMsg =
            case ( msg, model ) of
                ( GotIdentifyMessage imsg, Identify subModel ) ->
                    Just (Identify.update imsg subModel)

                ( GotIdentifyRemoteMessage imsg, IdentifyRemote subModel ) ->
                    Just (IdentifyRemote.update imsg subModel)

                ( GotRegisterMessage imsg, Register subModel ) ->
                    Just (Register.update imsg subModel)

                ( GotMoreInfoNeededMessage imsg, MoreInfoNeeded subModel ) ->
                    Just (MoreInfoNeeded.update imsg subModel)

                ( GotRegisterDeviceMessage imsg, RegisterDevice subModel ) ->
                    Just (RegisterDevice.update imsg subModel)

                ( GotRegisterDeviceMessageRemote imsg, RegisterCredentialRemote subModel ) ->
                    Just (RegisterCredentialRemote.update imsg subModel)

                ( GotDoneMessage imsg, LoginComplete subModel ) ->
                    Just (Done.update imsg subModel)

                ( GotDashboardMessage imsg, Dashboard subModel ) ->
                    Just (Dashboard.update imsg subModel)

                ( _, _ ) ->
                    Nothing

        isLocal origin u =
            case origin.basePath of
                Nothing ->
                    True

                Just bp ->
                    String.startsWith ("/" ++ bp) u.path

        handleThisMsg =
            case ( msg, model ) of
                ( ClickedLink urlRequest, _ ) ->
                    case urlRequest of
                        Browser.Internal url ->
                            if isLocal (getSessionDetails model).origin url then
                                ( model
                                , Nav.pushUrl (getNavKey model) (Url.toString url)
                                )

                            else
                                ( model
                                , Nav.load (Url.toString url)
                                )

                        Browser.External href ->
                            ( model
                            , Nav.load href
                            )

                ( ChangedUrl url, _ ) ->
                    changeRouteFromUrlTo (getSessionDetails model).origin url model

                ( _, _ ) ->
                    ( model, Cmd.none )
    in
    Maybe.withDefault handleThisMsg handleSubMsg


getOrigin : Model -> Types.Origin
getOrigin model =
    (getSessionDetails model).origin


getAuthenticatedUser : Model -> AuthenticatedUser
getAuthenticatedUser model =
    (getSessionDetails model).authenticatedUser


getNavKey : Model -> Nav.Key
getNavKey model =
    (getSessionDetails model).navKey


getCurrentRoute : Model -> Route
getCurrentRoute model =
    (getSessionDetails model).currentRoute


getSessionDetails : Model -> SessionDetails
getSessionDetails model =
    case model of
        Identify (Session.Session subModel) ->
            subModel.sessionDetails

        Register (Session.Session subModel) ->
            subModel.sessionDetails

        MoreInfoNeeded (Session.Session subModel) ->
            subModel.sessionDetails

        RegisterDevice (Session.Session subModel) ->
            subModel.sessionDetails

        RegisterCredentialRemote (Session.Session subModel) ->
            subModel.sessionDetails

        IdentifyRemote (Session.Session subModel) ->
            subModel.sessionDetails

        Dashboard (Session.Session subModel) ->
            subModel.sessionDetails

        NotFound _ subModel ->
            subModel

        FatalError sess ->
            sess

        LoginComplete (Session.Session subModel) ->
            subModel.sessionDetails


getTitle : Model -> String
getTitle model =
    case model of
        Identify (Session.Session sm) ->
            Identify.getTitle sm.subModel

        IdentifyRemote _ ->
            "Scan and Identify"

        Register _ ->
            "Register an Account"

        MoreInfoNeeded _ ->
            "More Info Needed"

        RegisterDevice (Session.Session sm) ->
            RegisterDevice.getTitle sm.subModel

        RegisterCredentialRemote _ ->
            "Scan and Register Credentials"

        NotFound _ _ ->
            "Something Went Wrong"

        FatalError _ ->
            "End of the Road"

        LoginComplete (Session.Session subModel) ->
            case subModel.sessionDetails.authenticatedUser of
                PendingAuth _ ->
                    "Logging you in..."

                _ ->
                    "All Done"

        Dashboard _ ->
            "Dashboard"


fatalErrorOccured : Model -> Maybe String
fatalErrorOccured model =
    (getSessionDetails model).fatalError


changeRouteFromUrlTo : Origin -> Url -> Model -> ( Model, Cmd Msg )
changeRouteFromUrlTo origin url model =
    case Route.fromUrl (Maybe.withDefault "/" origin.basePath) url of
        Nothing ->
            ( NotFound
                (Url.toString url)
                (getSessionDetails model
                    |> (\m ->
                            { m
                                | currentRoute = Route.NotFound
                                , timedOutRoute = Route.NotFound
                            }
                       )
                )
            , Cmd.none
            )

        Just rt ->
            changeRouteTo origin rt ( model, Cmd.none )


changeRouteTo : Origin -> Route -> ( Model, Cmd Msg ) -> ( Model, Cmd Msg )
changeRouteTo origin route ( model, cmd ) =
    let
        sessionDetails =
            getSessionDetails model

        ( nm, nmsg ) =
            case ( route, model ) of
                ( Route.Identify, _ ) ->
                    Identify.init
                        Identify
                        GotIdentifyMessage
                        { sessionDetails | authenticatedUser = PendingAuth (decideAuthType sessionDetails.authenticatedUser) }

                ( Route.IdentifyRemote _, IdentifyRemote _ ) ->
                    ( model, cmd )

                ( Route.IdentifyRemote (Just iktok), _ ) ->
                    identifyRemoteInit sessionDetails iktok

                ( Route.Register, Register _ ) ->
                    ( model, cmd )

                ( Route.Register, _ ) ->
                    Register.init
                        Register
                        GotRegisterMessage
                        sessionDetails

                ( Route.MoreInfoNeeded, _ ) ->
                    MoreInfoNeeded.init
                        MoreInfoNeeded
                        GotMoreInfoNeededMessage
                        sessionDetails

                ( Route.RegisterDevice, RegisterDevice _ ) ->
                    ( model, cmd )

                ( Route.StartRegisterDeviceRemote _, RegisterCredentialRemote _ ) ->
                    ( model, cmd )

                ( Route.RegisterDevice, _ ) ->
                    RegisterDevice.init
                        sessionDetails.currentRoute
                        RegisterDevice
                        GotRegisterDeviceMessage
                        sessionDetails

                ( Route.Dashboard, Dashboard _ ) ->
                    ( model, cmd )

                ( Route.Dashboard, _ ) ->
                    Dashboard.init Dashboard GotDashboardMessage sessionDetails

                ( Route.Done, _ ) ->
                    Done.initLoginSlow
                        LoginComplete
                        GotDoneMessage
                        sessionDetails

                _ ->
                    ( NotFound
                        (Route.routeToString origin Route.NotFound)
                        (getSessionDetails model
                            |> (\m ->
                                    { m
                                        | currentRoute = Route.NotFound
                                        , timedOutRoute = Route.NotFound
                                    }
                               )
                        )
                    , Cmd.none
                    )
    in
    ( nm, Cmd.batch [ nmsg, cmd ] )


decideAuthType : AuthenticatedUser -> AuthType
decideAuthType au =
    case au of
        PendingAuth at ->
            at

        Authenticated _ _ at ->
            at

        _ ->
            LocalRoute Route.Dashboard


viewBySubModel : Model -> Html Msg
viewBySubModel model =
    case fatalErrorOccured model of
        Just str ->
            let
                ( cn, hp ) =
                    (getSessionDetails model).errUrl
            in
            SharedViews.renderStop str cn (href <| Url.toString (baseToURL hp))

        Nothing ->
            case model of
                Identify subModel ->
                    Html.map GotIdentifyMessage <| Identify.view subModel

                IdentifyRemote subModel ->
                    Html.map GotIdentifyRemoteMessage <| IdentifyRemote.view subModel

                Register subModel ->
                    Html.map GotRegisterMessage <| Register.view subModel

                RegisterDevice subModel ->
                    Html.map GotRegisterDeviceMessage <| RegisterDevice.view subModel

                RegisterCredentialRemote subModel ->
                    Html.map GotRegisterDeviceMessageRemote <| RegisterCredentialRemote.view subModel

                Dashboard subModel ->
                    Html.map GotDashboardMessage <| Dashboard.view subModel

                NotFound str sess ->
                    let
                        red =
                            case sess.authenticatedUser of
                                PendingAuth _ ->
                                    a [ Route.href (getSessionDetails model).origin Route.Identify ]
                                        [ text "Start identification here" ]

                                _ ->
                                    a [ href <| Url.toString (baseToURL (Tuple.second (getSessionDetails model).errUrl)) ]
                                        [ text "Back to MatrixId home" ]
                    in
                    div []
                        [ SharedViews.notFound str
                        , p [ class "is-size-2-mobile" ]
                            [ red ]
                        ]

                FatalError sess ->
                    SharedViews.progressView "An error has occured...." sess.errUrl

                LoginComplete ses ->
                    Html.map GotDoneMessage <| Done.view ses

                MoreInfoNeeded sess ->
                    Html.map GotMoreInfoNeededMessage <| MoreInfoNeeded.view sess
