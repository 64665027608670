import {
  AsYouType,
  CountryCode
} from 'libphonenumber-js'


type MSISDNAction = "asYouType" | "clear"

interface MSISDNActionMessage {
  action: MSISDNAction,
  text: string,
  controlId: string
}


export class PhoneNumberManager {
  private app: any;

  constructor(app: any) {
    this.app = app;
    this.app.ports.asYouTypeMSISDN.subscribe((act: MSISDNActionMessage) => {
      requestAnimationFrame(() => {
        const target = <HTMLInputElement>document.getElementById(act.controlId)
        if (act.action == "asYouType") {
          const ast = new AsYouType();
          const res = ast.input(act.text);
          const pr = {
            text: res,
            formatted: ast.getNumber()?.format("INTERNATIONAL"),
            isValid: ast.isValid(),
            asE164: this.toE164(ast)
          }
          if (target) {
            target.value = pr.formatted ?? act.text
          }
          console.log(pr);
          this.app.ports.asYouTypeMSISDNResponse.send(this.clearUndefined(pr));
        } else if (target) {
          target.value = ""
        }
      })
    });
  }

  public toE164(ast: AsYouType): string | undefined {
    const phoneNumber = ast.getNumber();
    if (phoneNumber) {
      return phoneNumber.format('E.164')
    } else {
      return undefined;
    }
  }

  private clearUndefined(t: any) {
    for (let key in t) {
      if (t[key] === undefined) {
        delete t[key]
      }
    }
    return t;
  }
}
