import { Base64 } from "js-base64";

function getKeyMaterial(password: string) {
    let enc = new TextEncoder();
    return window.crypto.subtle.importKey(
        "raw",
        enc.encode(password),
        "PBKDF2",
        false,
        ["deriveBits", "deriveKey"]
    );
}

interface EncryptedPayload {
    encrypted: Uint8Array,
    iv: Uint8Array,
    salt: Uint8Array
}

async function encrypt(code: string, plaintext: string) {
    let iv = window.crypto.getRandomValues(new Uint8Array(12));
    let salt = window.crypto.getRandomValues(new Uint8Array(12));
    let keyMaterial = await getKeyMaterial(code);
    let key = await window.crypto.subtle.deriveKey(
        {
            name: "PBKDF2",
            salt: salt,
            iterations: 100000,
            hash: "SHA-256"
        },
        keyMaterial,
        { name: "AES-GCM", length: 256 },
        true,
        ["encrypt"]
    );
    let enc = new TextEncoder();
    let penc = enc.encode(plaintext);
    let encrypted = await window.crypto.subtle.encrypt(
        {
            name: "AES-GCM",
            iv: iv
        },
        key,
        penc
    );
    return {
        encrypted: encrypted,
        iv: iv,
        salt: salt
    }
}

async function decrypt(code: string, payload: EncryptedPayload) {
    let keyMaterial = await getKeyMaterial(code);
    let key = await window.crypto.subtle.deriveKey(
        {
            name: "PBKDF2",
            salt: payload.salt,
            iterations: 100000,
            hash: "SHA-256"
        },
        keyMaterial,
        { name: "AES-GCM", length: 256 },
        true,
        ["decrypt"]
    );
    return window.crypto.subtle.decrypt(
        {
            name: "AES-GCM",
            iv: payload.iv
        },
        key,
        payload.encrypted
    );
}

type CryptoAction = "encrypt" | "decrypt";

interface CryptoActionMessage {
    action: CryptoAction,
    payload: any
}

interface ElmToDecrypt {
    encrypted: string,
    iv: string,
    salt: string,
    code: string
}

interface ElmToEncrypt {
    plaintext: string
}


export class CryptoService {
    private app: any;

    constructor(app: any) {
        this.app = app;
        this.app.ports.doCryptoAction.subscribe((act: CryptoActionMessage) => {
            if (act.action == "encrypt") {
                const eep = act.payload as ElmToEncrypt
                this.encrypt(eep);
            } else if (act.action == "decrypt") {
                const eep = act.payload as ElmToDecrypt
                this.decrypt(eep);
            }
        });
    }

    async decrypt(eep: ElmToDecrypt) {
        try {
            const res = await decrypt(eep.code, {
                encrypted: Base64.toUint8Array(eep.encrypted),
                iv: Base64.toUint8Array(eep.iv),
                salt: Base64.toUint8Array(eep.salt)
            });
            const resp = {
                decryptResponse: Base64.fromUint8Array(new Uint8Array(res))
            }
            this.app.ports.cryptoResponse.send(resp)
        } catch (err) {
            console.error("Decryption failed: " + err);
            this.notifyError(err)
        }
    }

    async encrypt(eep: ElmToEncrypt) {
        try {
            const code = this.generate(6);
            const res = await encrypt(code, eep.plaintext);
            this.app.ports.cryptoResponse.send(
                {
                    encryptResponse: {
                        encrypted: Base64.fromUint8Array(new Uint8Array(res.encrypted), true),
                        iv: Base64.fromUint8Array(res.iv),
                        salt: Base64.fromUint8Array(res.salt),
                        code: code
                    }
                }
            )
        } catch (err) {
            console.error("Encryption failed: " + err);
            this.notifyError(err)
        }
    }

    private generate(n:number) : string {
        var add = 1, max = 12 - add;   // 12 is the min safe number Math.random() can generate without it starting to pad the end with zeros.   

        if (n > max) {
            return this.generate(max) + this.generate(n - max);
        }

        max = Math.pow(10, n + add);
        var min = max / 10; // Math.pow(10, n) basically
        var number = Math.floor(Math.random() * (max - min + 1)) + min;

        return ("" + number).substring(add);
    }

    private notifyError(err: Error) {
        console.error(err)
        this.app.ports.cryptoResponse.send(
            {
                errorResponse: err.name + ": " + err.message
            }
        );
    }
}
