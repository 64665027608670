import * as H from '@vladmandic/human'

const CANVAS_WIDTH = 480;

const idFaceConfig = { // user configuration for human, used to fine-tune behavior
    modelBasePath: 'models',
    filter: { enabled: true, equalization: true }, // lets run with histogram equilizer
    debug: true,
    face: {
        enabled: true,
        detector: { rotation: true, return: true, mask: false }, // return tensor is used to get detected face image
        description: { enabled: true }, // default model for face descriptor extraction is faceres
        // mobilefacenet: { enabled: true, modelPath: 'https://vladmandic.github.io/human-models/models/mobilefacenet.json' }, // alternative model
        // insightface: { enabled: true, modelPath: 'https://vladmandic.github.io/insightface/models/insightface-mobilenet-swish.json' }, // alternative model
        iris: { enabled: true }, // needed to determine gaze direction
        antispoof: { enabled: true }, // enable optional antispoof module
        liveness: { enabled: true }, // enable optional liveness module
        emotion: { enabled: false }
    },
    hand: { enabled: false },
    body: { enabled: false }
};

const matchOptions = { order: 2, multiplier: 25, min: 0.2, max: 0.8 }; // for faceres model

const options = {
    minConfidence: 0.85, // overal face confidence for box, face, gender, real, live
    minSize: 224, // min input to face descriptor model before degradation
    maxTime: 30000, // max time before giving up
    blinkMin: 10, // minimum duration of a valid blink
    blinkMax: 800, // maximum duration of a valid blink
    threshold: 0.85, // minimum similarity
    distanceMin: 0.4, // closest that face is allowed to be to the cammera in cm
    distanceMax: 1.0, // farthest that face is allowed to be to the cammera in cm
    mask: idFaceConfig.face.detector.mask,
    rotation: idFaceConfig.face.detector.rotation,
    ...matchOptions,
};

const drawOptions: H.DrawOptions = {
    color: 'rgba(0, 178, 0, 0.49)' as string, // 'lightblue' with light alpha channel
    labelColor: 'rgba(0, 178, 0, 1)' as string, // 'lightblue' with dark alpha channel
    shadowColor: 'black' as string,
    alpha: 0.5 as number,
    font: 'small-caps 16px "Segoe UI"' as string,
    lineHeight: 18 as number,
    lineWidth: 4 as number,
    pointSize: 2 as number,
    roundRect: 8 as number,
    drawPoints: false as boolean,
    drawLabels: true as boolean,
    drawBoxes: true as boolean,
    drawAttention: false as boolean,
    drawGestures: false as boolean,
    drawPolygons: false as boolean,
    drawGaze: false as boolean,
    fillPolygons: false as boolean,
    useDepth: true as boolean,
    useCurves: false as boolean,
    faceLabels: '' as string,
    bodyLabels: '' as string,
    bodyPartLabels: '' as string,
    objectLabels: '' as string,
    handLabels: '' as string,
    fingerLabels: '' as string,
    gestureLabels: '' as string,
};

type IDFaceAction = "startVideo" | "renderToCanvas" | "stopVideo" | "postVideo"

interface PostVideoMsg {
    url: string,
    authToken: string
}
interface IDFaceMsg {
    action: IDFaceAction
    , payload: any
}

interface StartVideo {
    videoElementId: string,
    canvasElementId: string
}

interface IdFaceResult {
    error: string | undefined
}

interface FaceAPIResponse {
    faceDetectResponse: string,
    verifyAttemptToken: string | undefined
}

type IdState = 'starting' | 'detecting' | 'stopped' | 'pause' | 'error'

type FaceState = 'faceFound' | 'notFound' | 'newFaceFound' | 'foundEnoughFaces' | 'noVideo'

declare type FaceDetectorHandler = (fs: FaceState) => void;

export class IDFace {
    private human: H.Human | undefined
    private app: any;
    private stream: MediaStream | undefined;
    private state: IdState;
    private mediaRecorder: MediaRecorder | undefined;
    private handleFaceEvent: FaceDetectorHandler;
    private chunks: Blob | undefined;
    private bestMimeType: string;
    private started: boolean = false;

    constructor(app: any) {
        this.app = app;
        this.state = 'starting';
        this.handleFaceEvent = (fs: FaceState) => {
            if (fs == 'foundEnoughFaces') { this.stopVideo() }
            this.app.ports.idFaceResult.send(fs);
        }
        if (MediaRecorder.isTypeSupported("video/mp4")) {
            this.bestMimeType = "video/mp4";
        } else if (MediaRecorder.isTypeSupported("video/webm")) {
            this.bestMimeType = "video/webm";
        } else {
            this.bestMimeType = "video/webm";
            this.app.ports.idFaceResult.send('noVideo');
        }
    }

    public start() {
        if (!this.started) {
            this.human = new H.Human(idFaceConfig); // create instance of human with overrides from user configuration
            this.human.env.perfadd = false; // is performance data showing instant or total values
            this.human.draw.options.font = 'small-caps 18px "Lato"'; // set font used to draw labels when using draw methods
            this.human.draw.options.lineHeight = 20;
            this.human.warmup().then(() => {
                this.app.ports.doIdFaceAction.subscribe((act: IDFaceMsg) => {
                    if (act.action == "startVideo") {
                        let sv = act.payload as StartVideo;
                        this.startVideo(sv.videoElementId, sv.canvasElementId,
                            msg => {
                                console.error(msg);
                                this.app.ports.idFaceResult.send(msg);
                            })
                        console.debug("Starting video...");
                    } else if (act.action == "stopVideo") {
                        this.stopVideo();
                    } else if (act.action == "postVideo") {
                        const msgPayload = act.payload as PostVideoMsg;
                        this.postChunks(msgPayload.authToken, msgPayload.url);
                    }
                });
                this.started = true;
                this.app.ports.idFaceResult.send('notFound');
            }
            )
        } else {
            this.app.ports.idFaceResult.send('notFound');
        }

    }

    public stop() {
        if (this.human) {
            this.human.tf.dispose();
        }
        this.started = false;
    }


    private async startVideo(videoId: string, canvasId: string, failF: (arg0: string) => void) {
        if (this.human) {
            this.chunks = undefined;
            const video = document.getElementById(videoId) as HTMLVideoElement;
            const canvasElement = document.getElementById(canvasId) as HTMLCanvasElement;
            const cameraOptions: MediaStreamConstraints = { audio: false, video: { facingMode: 'user', width: { ideal: 640 } } };
            const mstream = await navigator.mediaDevices.getUserMedia(cameraOptions);
            this.mediaRecorder = new MediaRecorder(mstream, { mimeType: this.bestMimeType });
            const fsm = new FaceStateManager(this.human, this.handleFaceEvent, this.mediaRecorder, this.bestMimeType, (bs) => this.chunks = bs)
            this.stream = mstream;
            video.srcObject = mstream;
            video.play();
            const ctx = canvasElement.getContext("2d", { willReadFrequently: true });
            ctx?.translate(CANVAS_WIDTH, 0.0);
            ctx?.scale(-1.0, 1.0);
            ctx?.setTransform({ a: 1.0, b: 0.0, c: 0.0, d: 1.0, e: 0.0, f: 0.0 });
            this.state = "detecting";
            requestAnimationFrame(() => this.renderLoop(fsm, videoId, canvasId, undefined, failF));
            console.debug("Detection started...");
        } else {
            failF("Human is not configured");
            this.state = 'error';
        }
    }

    private renderLoop(faceStateManager: FaceStateManager, videoId: string, canvasId: string, currentFace: H.FaceResult | undefined, failF: (arg0: string) => void) {
        if (this.state != "stopped") {
            const videoElement = document.getElementById(videoId) as HTMLVideoElement;
            const canvasElement = document.getElementById(canvasId) as HTMLCanvasElement;
            if (videoElement.readyState == videoElement.HAVE_ENOUGH_DATA) {
                const ctx = canvasElement.getContext("2d", { willReadFrequently: true });
                if (ctx == null) {
                    failF("Failed to get 2D rendering context");
                    this.state = "error";
                } else {
                    const faceImg = this.drawImageScaled(ctx, videoElement);
                    if (currentFace) this.drawCurrentFace(canvasElement, currentFace);
                    if (this.state == 'detecting') {
                        this.human?.detect(faceImg).then((res: H.Result) => {
                            if (res.face[0]) { faceStateManager.faceFound(res.face[0]) }
                            this.checkStatusAndRender(canvasElement, res.face[0], faceStateManager, videoId, canvasId, failF)
                        })
                    }
                }
            } else {
                this.checkStatusAndRender(canvasElement, currentFace, faceStateManager, videoId, canvasId, failF)
            }
        }
    }

    private drawImageScaled(ctx: CanvasRenderingContext2D, videoElement: HTMLVideoElement): ImageData {
        const canvas = ctx.canvas;
        const hRatio = canvas.width / videoElement.videoWidth;
        const vRatio = canvas.height / videoElement.videoHeight;
        const ratio = Math.min(hRatio, vRatio);
        const centerShift_x = (canvas.width - videoElement.videoWidth * ratio) / 2;
        const centerShift_y = (canvas.height - videoElement.videoHeight * ratio) / 2;
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(videoElement, 0, 0, videoElement.videoWidth, videoElement.videoHeight,
            centerShift_x, centerShift_y, videoElement.videoWidth * ratio, videoElement.videoHeight * ratio);
        return ctx.getImageData(0, 0, ctx.canvas.width, ctx.canvas.height);
    }

    private checkStatusAndRender(canvasElement: HTMLCanvasElement, currentFace: H.FaceResult | undefined, fsm: FaceStateManager, videoId: string, canvasId: string, failF: (arg0: string) => void) {
        if (this.state == 'starting' || this.state == 'detecting') {
            if (currentFace) this.drawCurrentFace(canvasElement, currentFace);
            requestAnimationFrame(() => this.renderLoop(fsm, videoId, canvasId, currentFace, failF))
        }
    }

    private drawCurrentFace(canvasElement: HTMLCanvasElement, currentFace: H.FaceResult) {
        this.human?.tf.dispose(currentFace.tensor);
        H.draw.face(canvasElement, [currentFace], drawOptions);
        console.debug("Drew current face info...")
    }

    private stopVideo() {
        if (this.stream) {
            this.stream.getTracks().forEach(track => {
                track.stop();
            });
        }
        this.state = "stopped";
    }


    public postChunks(authToken: string, url: string) {
        if (this.chunks) {
            const dict = { 'Authorization': authToken }
            const callApi = async () => {
                const response = await fetch(url, { method: "POST", headers: new Headers(dict), body: this.chunks })
                if (response.ok) {
                    const obj: FaceAPIResponse = await response.json();
                    this.app.ports.idFaceSubmitResult.send({ faceDetected: obj })
                }
                else if (response.status == 401) {
                    const resp = await response.json();
                    this.app.ports.idFaceSubmitResult.send({ recoverByRecoveryPartyIdentifier: resp })
                } else if (response.status == 403) {
                    this.app.ports.idFaceSubmitResult.send({ recoveryFailed: null })
                } else {
                    throw Error(`Server returned ${response.status}: ${response.statusText}`)
                }
            }
            callApi().catch(err =>
                this.app.ports.idFaceSubmitResult.send({ detectionError: err.message })
            );
        }
        else {
            this.app.ports.idFaceResult.send('noVideoToPost');
        }
    }
}


class FaceStateManager {

    private currentFaces: H.FaceResult[] = [];
    private human: H.Human;
    private faceDetectionHandler: FaceDetectorHandler;
    private mediaRecorder: MediaRecorder
    private chunks: Blob[] = [];
    private saveChunks: (bs: Blob) => void;

    constructor(human: H.Human, faceDetectionHandler: FaceDetectorHandler, mediaRecorder: MediaRecorder, mimetype: string, saveChunks: (bs: Blob) => void) {
        this.human = human;
        this.faceDetectionHandler = faceDetectionHandler;
        this.mediaRecorder = mediaRecorder;
        this.mediaRecorder.ondataavailable = (e) => {
            if (e.data.size > 0) {
                this.chunks.push(e.data);
            }
        };
        this.mediaRecorder.onstop = () => {
            if (this.chunks.length > 0) {
                const blob = new Blob(this.chunks);//, { type: mimetype });
                this.saveChunks(blob);
                this.faceDetectionHandler('foundEnoughFaces');
            } else {
                this.faceDetectionHandler('noVideo');
            }
        }
        this.saveChunks = saveChunks;
    }


    /**
     * faceFound
     */
    public faceFound(face: H.FaceResult) {
        if (face.score > 0.7) {
            if (this.currentFaces.length > 0) {
                let tail = this.currentFaces.slice(-1)[0];
                if (tail.embedding && face.embedding) {
                    if (this.human.match.similarity(tail.embedding, face.embedding) > 0.5) {
                        this.currentFaces.push(face);
                        if (this.currentFaces.length > 10 && this.chunks.length > 1) {
                            this.mediaRecorder.stop();
                        }
                    } else {
                        this.currentFaces = [];
                        this.mediaRecorder.stop();
                        this.faceDetectionHandler('newFaceFound');
                    }
                }

            } else {
                this.mediaRecorder.start(1000);
                this.currentFaces.push(face);
                this.faceDetectionHandler('faceFound');
            }
        } else {
            this.faceDetectionHandler('notFound');
            this.currentFaces = [];
            this.mediaRecorder.stop();
        }
    }
}